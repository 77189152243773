var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    staticClass: "mt-4",
    attrs: {
      "header-bg-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('h5', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Bulk Create Audio")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "4"
          }
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.handleReset();
            }
          }
        }, [_vm._v("Reset")])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.permFilterVendor() ? _c('b-form-group', {
    attrs: {
      "label": "Vendor"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Vendor",
      "open-direction": "bottom",
      "options": _vm.vendorSearchOptions,
      "searchable": true,
      "loading": _vm.isVendorSearch,
      "close-on-select": true,
      "multiple": false
    },
    on: {
      "search-change": _vm.vendorFind
    },
    model: {
      value: _vm.vendor,
      callback: function ($$v) {
        _vm.vendor = $$v;
      },
      expression: "vendor"
    }
  })], 1) : _vm._e(), _c('b-row', [_c('b-form-group', {
    staticClass: "col"
  }, [_c('b-form-file', {
    attrs: {
      "accept": ".csv",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here..."
    },
    model: {
      value: _vm.file_csv,
      callback: function ($$v) {
        _vm.file_csv = $$v;
      },
      expression: "file_csv"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3"
  }, [_c('b-link', {
    staticClass: "btn btn-outline",
    staticStyle: {
      "color": "#28a745",
      "border-color": "#28a745"
    },
    attrs: {
      "href": _vm.URL_STATIC.TEMPLATE_BULK_CREATE_AUDIO
    }
  }, [_vm._v(" Template "), _c('i', {
    staticClass: "fa fa-file-excel-o"
  })])], 1)], 1)], 1), _c('b-col', _vm._l(_vm.resultBulkCreate, function (value, key, index) {
    return _c('li', {
      key: index
    }, [_c('b', [_vm._v(" " + _vm._s(value.name) + " ")]), _vm._v(" → "), _vm._v(" " + _vm._s(value.message) + " " + _vm._s(value.error ? '❌' : '✅') + " ")]);
  }), 0)], 1)], 1), _c('b-card', {
    attrs: {
      "header-bg-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('h5', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Review Data")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "4"
          }
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.handleAdd();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Submit ")])], 1), _c('b-col', {
    staticClass: "text-right"
  }, [_vm.isBulkNarratorShow() ? _c('b-button', {
    staticClass: "badge badge-primary",
    on: {
      "click": _vm.onBulkCreateNarrator
    }
  }, [_vm._v("create all narrator")]) : _vm._e()], 1)], 1), _c('b-table', {
    staticStyle: {
      "white-space": "unset",
      "min-height": "300px"
    },
    attrs: {
      "show-empty": "",
      "striped": "",
      "hover": "",
      "sticky-header": "600px",
      "items": _vm.itemsAudio,
      "fields": _vm.fields,
      "tbody-tr-class": _vm.rowClass
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " ")])];
      }
    }, {
      key: "head(release_date)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " (GMT" + _vm._s(_vm.timezoneString()) + ") ")])];
      }
    }, {
      key: "head(release_schedule)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.label) + " (GMT" + _vm._s(_vm.timezoneString()) + ") ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + ". "), _c('i', {
          staticClass: "fa fa-trash fa-2 text text-danger",
          staticStyle: {
            "cursor": "pointer",
            "font-size": "24px"
          },
          on: {
            "click": function ($event) {
              return _vm.handleDelete(data);
            }
          }
        })];
      }
    }, {
      key: "cell(parent_id)",
      fn: function (data) {
        return [_vm.itemsAudio[data.index].isSearchParent ? _c('b-spinner', {
          attrs: {
            "small": "",
            "label": "Small Spinner"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.itemsAudio[data.index].isSearchParent) + " "), _c('b-form-input', {
          attrs: {
            "type": "text"
          },
          on: {
            "change": function ($event) {
              return _vm.onChangeParentId(data.item.parent_id, data.index);
            }
          },
          model: {
            value: data.item.parent_id,
            callback: function ($$v) {
              _vm.$set(data.item, "parent_id", $$v);
            },
            expression: "data.item.parent_id"
          }
        }), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm.itemsParent[data.item.parent_id] ? _c('router-link', {
          attrs: {
            "target": "_blank",
            "to": {
              name: _vm.detail_items_path.name,
              params: {
                id: data.item.parent_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.itemsParent[data.item.parent_id].name) + " ")]) : _c('span', [_vm._v(" " + _vm._s(data.item.parent_id) + " item not found! ")])], 1)];
      }
    }, {
      key: "cell(name)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          model: {
            value: _vm.itemsAudio[data.index].name,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "name", $$v);
            },
            expression: "itemsAudio[data.index].name"
          }
        })];
      }
    }, {
      key: "cell(edition_code)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          model: {
            value: _vm.itemsAudio[data.index].edition_code,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "edition_code", $$v);
            },
            expression: "itemsAudio[data.index].edition_code"
          }
        })];
      }
    }, {
      key: "cell(issue_number)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          model: {
            value: _vm.itemsAudio[data.index].issue_number,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "issue_number", $$v);
            },
            expression: "itemsAudio[data.index].issue_number"
          }
        })];
      }
    }, {
      key: "cell(description)",
      fn: function (data) {
        return [_c('b-form-textarea', {
          staticStyle: {
            "width": "300px"
          },
          model: {
            value: _vm.itemsAudio[data.index].description,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "description", $$v);
            },
            expression: "itemsAudio[data.index].description"
          }
        })];
      }
    }, {
      key: "cell(release_date)",
      fn: function (data) {
        return [_c('DatetimeCustom', {
          staticClass: "text-nowrap",
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "label": ''
          },
          model: {
            value: _vm.itemsAudio[data.index].release_date,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "release_date", $$v);
            },
            expression: "itemsAudio[data.index].release_date"
          }
        })];
      }
    }, {
      key: "cell(release_schedule)",
      fn: function (data) {
        return [_c('DatetimeCustom', {
          staticClass: "text-nowrap",
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "label": ''
          },
          model: {
            value: _vm.itemsAudio[data.index].release_schedule,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "release_schedule", $$v);
            },
            expression: "itemsAudio[data.index].release_schedule"
          }
        })];
      }
    }, {
      key: "cell(category_ax)",
      fn: function (data) {
        return [_c('multiselect', {
          staticStyle: {
            "min-width": "200px"
          },
          attrs: {
            "label": "name",
            "track-by": "name",
            "placeholder": "Category Ax",
            "open-direction": "bottom",
            "options": _vm.categoryAxSearchOptions,
            "searchable": true,
            "loading": _vm.isAxSearch,
            "close-on-select": true,
            "multiple": false
          },
          on: {
            "search-change": _vm.categoryAxFind
          },
          model: {
            value: _vm.itemsAudio[data.index].category_ax,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "category_ax", $$v);
            },
            expression: "itemsAudio[data.index].category_ax"
          }
        })];
      }
    }, {
      key: "cell(narrator)",
      fn: function (data) {
        return [_c('multiselect', {
          staticStyle: {
            "min-width": "200px"
          },
          attrs: {
            "label": "name",
            "track-by": "name",
            "placeholder": "Enter Narrator",
            "open-direction": "bottom",
            "options": _vm.narratorSearchOptions,
            "loading": _vm.isNarratorSearch,
            "searchable": true,
            "close-on-select": true,
            "multiple": true
          },
          on: {
            "search-change": _vm.narratorFind
          },
          model: {
            value: _vm.itemsAudio[data.index].narrator,
            callback: function ($$v) {
              _vm.$set(_vm.itemsAudio[data.index], "narrator", $$v);
            },
            expression: "itemsAudio[data.index].narrator"
          }
        }), _vm._l(_vm.narratorNotFound[data.item.parent_id], function (narrator) {
          return _c('b-row', {
            key: narrator
          }, [_c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_vm._v(" " + _vm._s(narrator) + " "), _c('b-button', {
            staticClass: "badge badge-primary",
            on: {
              "click": function ($event) {
                return _vm.onCreateNarrator(narrator);
              }
            }
          }, [_vm._v("create")])], 1)], 1);
        })];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }