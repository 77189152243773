<template>
  <b-overlay
    :show="isLoading"
    rounded="lg"
    opacity="0.6"
    spinner-variant="primary"
  >
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-card header-bg-variant="primary" class="mt-4">
      <template #header>
        <b-row align-h="between">
          <b-col cols="4">
            <h5 class="font-weight-bold">Bulk Create Audio</h5>
          </b-col>
          <b-col cols="4" class="text-right">
            <b-button size="sm" variant="outline-danger" @click="handleReset()"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col md="6">
          <b-form-group label="Vendor" v-if="permFilterVendor()">
            <multiselect
              v-model="vendor"
              label="name"
              track-by="name"
              placeholder="Enter Vendor"
              open-direction="bottom"
              :options="vendorSearchOptions"
              :searchable="true"
              :loading="isVendorSearch"
              :close-on-select="true"
              :multiple="false"
              @search-change="vendorFind"
            >
            </multiselect>
          </b-form-group>
          <b-row>
            <b-form-group class="col">
              <b-form-file
                v-model="file_csv"
                accept=".csv"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              >
              </b-form-file>
            </b-form-group>
            <b-form-group class="col-md-3">
              <b-link
                class="btn btn-outline"
                :href="URL_STATIC.TEMPLATE_BULK_CREATE_AUDIO"
                style="color: #28a745; border-color: #28a745"
              >
                Template <i class="fa fa-file-excel-o"></i>
              </b-link>
            </b-form-group>
          </b-row>
        </b-col>

        <!-- result submit bulk audio -->
        <b-col>
          <li v-for="(value, key, index) in resultBulkCreate" :key="index">
            <b>
              {{ value.name }}
            </b>
            &#8594;
            <!-- right arrow -->
            {{ value.message }}
            {{ value.error ? '&#10060;' : '&#9989;' }}
          </li>
        </b-col>
      </b-row>
    </b-card>

    <!-- without parent -->
    <b-card header-bg-variant="primary">
      <template #header>
        <b-row align-h="between">
          <b-col cols="4">
            <h5 class="font-weight-bold">Review Data</h5>
          </b-col>
          <b-col cols="4" class="text-right">
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="handleAdd()"
            >
              <i class="fa fa-plus"></i>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col>
          <b-button variant="primary" type="submit" @click="onSubmit">
            Submit
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            class="badge badge-primary"
            @click="onBulkCreateNarrator"
            v-if="isBulkNarratorShow()"
            >create all narrator</b-button
          >
        </b-col>
      </b-row>
      <b-table
        show-empty
        striped
        hover
        sticky-header="600px"
        :items="itemsAudio"
        :fields="fields"
        :tbody-tr-class="rowClass"
        style="white-space: unset; min-height: 300px"
      >
        <template #head()="row">
          <div class="text-nowrap">
            {{ row.label }}
          </div>
        </template>
        <template #head(release_date)="row">
          <div class="text-nowrap">
            {{ row.label }} (GMT{{ timezoneString() }})
          </div>
        </template>
        <template #head(release_schedule)="row">
          <div class="text-nowrap">
            {{ row.label }} (GMT{{ timezoneString() }})
          </div>
        </template>
        <template #cell(action)="data">
          {{ data.index + 1 }}.
          <i
            class="fa fa-trash fa-2 text text-danger"
            style="cursor: pointer; font-size: 24px"
            @click="handleDelete(data)"
          ></i>
        </template>
        <template #cell(parent_id)="data">
          <b-spinner
            small
            label="Small Spinner"
            v-if="itemsAudio[data.index].isSearchParent"
          ></b-spinner>
          {{ itemsAudio[data.index].isSearchParent }}
          <b-form-input
            type="text"
            v-model="data.item.parent_id"
            @change="onChangeParentId(data.item.parent_id, data.index)"
          />
          <div class="text-nowrap">
            <router-link
              target="_blank"
              :to="{
                name: detail_items_path.name,
                params: { id: data.item.parent_id },
              }"
              v-if="itemsParent[data.item.parent_id]"
            >
              {{ itemsParent[data.item.parent_id].name }}
            </router-link>
            <span v-else> {{ data.item.parent_id }} item not found! </span>
          </div>
        </template>
        <template #cell(name)="data">
          <b-form-input
            v-model="itemsAudio[data.index].name"
            style="width: 200px"
          />
        </template>
        <template #cell(edition_code)="data">
          <b-form-input
            v-model="itemsAudio[data.index].edition_code"
            style="width: 200px"
          />
        </template>
        <template #cell(issue_number)="data">
          <b-form-input
            v-model="itemsAudio[data.index].issue_number"
            style="width: 200px"
          />
        </template>
        <template #cell(description)="data">
          <b-form-textarea
            v-model="itemsAudio[data.index].description"
            style="width: 300px"
          >
          </b-form-textarea>
        </template>
        <template #cell(release_date)="data">
          <DatetimeCustom
            class="text-nowrap"
            :label="''"
            v-model="itemsAudio[data.index].release_date"
            style="width: 200px"
          ></DatetimeCustom>
        </template>
        <template #cell(release_schedule)="data">
          <DatetimeCustom
            class="text-nowrap"
            :label="''"
            v-model="itemsAudio[data.index].release_schedule"
            style="width: 200px"
          ></DatetimeCustom>
        </template>
        <template #cell(category_ax)="data">
          <multiselect
            v-model="itemsAudio[data.index].category_ax"
            label="name"
            track-by="name"
            placeholder="Category Ax"
            open-direction="bottom"
            :options="categoryAxSearchOptions"
            :searchable="true"
            :loading="isAxSearch"
            :close-on-select="true"
            :multiple="false"
            @search-change="categoryAxFind"
            style="min-width: 200px"
          >
          </multiselect>
        </template>
        <template #cell(narrator)="data">
          <multiselect
            v-model="itemsAudio[data.index].narrator"
            label="name"
            track-by="name"
            placeholder="Enter Narrator"
            open-direction="bottom"
            :options="narratorSearchOptions"
            :loading="isNarratorSearch"
            :searchable="true"
            :close-on-select="true"
            :multiple="true"
            @search-change="narratorFind"
            style="min-width: 200px"
          >
          </multiselect>
          <b-row
            v-for="narrator in narratorNotFound[data.item.parent_id]"
            :key="narrator"
          >
            <b-col cols="12">
              {{ narrator }}
              <b-button
                class="badge badge-primary"
                @click="onCreateNarrator(narrator)"
                >create</b-button
              >
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import XLSX from 'xlsx';
import constant from '../../store/constant';
import { DetailItems } from '../../router/content';
import DatetimeCustom from '../../components/form/Datetime.vue';
import API from '../../plugins/http';

const { ROLES } = constant;

export default {
  name: 'BulkCreateAudio',
  components: {
    DatetimeCustom,
  },
  data() {
    return {
      fields: [
        { key: 'action' },
        { key: 'parent_id' },
        {
          key: 'name',
          stickyColumn: true,
        },
        { key: 'narrator' },
        { key: 'issue_number' },
        { key: 'edition_code' },
        { key: 'description' },
        { key: 'category_ax' },
        { key: 'release_date' },
        { key: 'release_schedule' },
      ],
      itemsAudio: [],
      itemsParent: {},
      csvDataRaw: [],
      categoryAxSearchOptions: [],
      narratorSearchOptions: [],
      narratorNotFound: {},
      resultBulkCreate: {},
      file_csv: null,
      vendor: null,
      isVendorSearch: false,
      isLoading: false,
      isAxSearch: false,
      isNarratorSearch: false,
      isSearchParent: {},
      URL_STATIC: constant.URL_STATIC,
      detail_items_path: DetailItems,
    };
  },
  created() {},
  watch: {
    file_csv() {
      if (!this.file_csv) {
        this.handleReset();
        return;
      }
      this.csvToTable();
    },
    ownVendor: function (data) {
      this.vendor = data;
    },
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapState({
      vendorSearchOptions: (state) => state.vendors.items,
      ownVendor: (state) => state.vendors.item,
    }),
  },
  methods: {
    ...mapActions('vendors', ['fetchOwnVendors', 'searchVendors']),
    ...mapActions('ax', ['searchCategoryAx']),
    ...mapActions('ebooks', ['fetchEbookById']),

    onSubmit() {
      this.isLoading = true;
      this.resultBulkCreate = {};

      if (!this.vendor?.id) {
        this.messageAlert('error', 'vendor not selected!', 5000);
        this.isLoading = false;
        return;
      }
      if (!this.itemsAudio.length) {
        this.messageAlert('error', 'Empty Data!', 5000);
        this.isLoading = false;
        return;
      }
      if (!this.isDataReadySubmit()) {
        this.isLoading = false;
        return;
      }

      (this.itemsAudio ?? []).forEach(async (item) => {
        item.isSubmit = true;
        const isExistAudiobBook = await this.isExistAudiobBook({
          name: item.name,
        });

        if (isExistAudiobBook?.error) {
          isExistAudiobBook.id = item.parent_id;
          isExistAudiobBook.name = item.name;
          this.resultBulkCreate = {
            ...this.resultBulkCreate,
            [item.parent_id]: isExistAudiobBook,
          };

          item.isSubmit = false;
          this.isLoadingDone();
          return;
        }

        const result = await this.postAudioBook({
          parent_item_id: item.parent_id,
          name: item.name,
          edition_code: item.edition_code,
          release_date: new Date(item.release_date).toISOString(),
          release_schedule: new Date(item.release_schedule).toISOString(),
          author_id: item.authors,
          languages: item.languages,
          countries: item.countries,
          brand_id: item.brand_id,
          issue_number: item.issue_number,
          narrator_id: item.narrator.map((val) => val.id),
          description: item.description,
          is_active: true,
        });

        if (result.error === false) {
          this.itemsAudio = this.itemsAudio.filter(
            (val) => val.parent_id != item.parent_id
          );
        }

        result.id = item.parent_id;
        result.name = item.name;
        this.resultBulkCreate = {
          ...this.resultBulkCreate,
          [item.parent_id]: result,
        };

        item.isSubmit = false;
        this.isLoadingDone();
      });
    },

    isDataReadySubmit() {
      for (const [idx, item] of this.itemsAudio.entries()) {
        const checkData = Object.values(item);
        if (
          checkData.includes(null) ||
          checkData.includes(undefined) ||
          checkData.includes('')
        ) {
          this.messageAlert(
            'error',
            `please fill in completely in row ${idx + 1}`,
            5000
          );
          return false;
        }
        if (!this.itemsParent[item.parent_id]?.id) {
          this.messageAlert(
            'error',
            `parent_id is not valid (row ${idx + 1})`,
            5000
          );
          return false;
        }
      }
      return true;
    },

    csvToTable() {
      const reader = new FileReader();
      reader.readAsBinaryString(this.file_csv);
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, {
          type: 'binary',
          cellDates: true,
          raw: true,
          dateNF: 'yyyy-mm-dd hh:mm:ss z',
        });
        let csvData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        this.csvDataRaw = csvData;
        this.validateDataCsv();
        this.getParentInfo();
      };
    },

    validateDataCsv() {
      this.isLoading = true;
      this.narratorNotFound = {};
      this.itemsAudio = this.csvDataRaw.map((item) => {
        const formatData = this.formatData({
          data: item,
          fields: this.fields,
        });
        return formatData;
      });

      this.itemsAudio.forEach(async (item) => {
        item.isSearchParent = false;
        item.isValidate = true;
        item.category_ax = await this.categoryAxBinding(item.category_ax);
        item.narrator = await this.narratorBindings(
          item.narrator,
          item.parent_id
        );
        item.isValidate = false;
        this.isLoadingDone();
      });
    },

    async postAudioBook({
      parent_item_id,
      name,
      edition_code,
      release_date,
      release_schedule,
      author_id,
      countries,
      languages,
      narrator_id,
      is_active = true,
      brand_id,
      issue_number,
    }) {
      try {
        await API.post(`items/audiobook`, {
          parent_item_id,
          name,
          edition_code,
          release_date,
          release_schedule,
          author_id,
          countries,
          languages,
          narrator_id,
          is_active,
          brand_id,
          issue_number,
        });
        return {
          error: false,
          message: 'success created',
        };
      } catch (error) {
        let statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        
        return {
          error: true,
          message: message ?? `error ${statusCode}`,
        };
      }
    },

    async isExistAudiobBook({ name = '' }) {
      try {
        const nameSearch = name.toLowerCase().trim();
        const response = await API.get(`items-search/audio%20book?q=${name}`);
        const rows = response.data?.data?.rows;
        const isExist = (rows ?? []).find((val) => {
          const nameResponse = val.name?.toLowerCase().trim();
          return nameResponse == nameSearch;
        });

        if (isExist) {
          return {
            error: true,
            message: 'item already exists',
          };
        }
        return {
          error: false,
          message: '',
        };
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        return {
          error: true,
          message: '0pps! please try again ' + statusCode,
        };
      }
    },

    async postNarrator({
      name = '',
      first_name = '',
      last_name = '',
      birthdate = '1990-01-01',
      is_active = true,
      image = 'null',
    }) {
      try {
        const response = await API.post('narrator', {
          name,
          first_name,
          last_name,
          birthdate,
          is_active,
          image,
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async searchNarrator(q = '') {
      try {
        const response = await API.get(`narrator?query=${q}`);
        return response;
      } catch (error) {
        return error;
      }
    },

    async categoryAxBinding(categoryAxName = '') {
      const caSearch = categoryAxName.trim().toLowerCase();
      const findAxFromOptions = this.categoryAxSearchOptions.find((caso) => {
        const casoName = caso.name?.trim().toLowerCase();
        return casoName === caSearch;
      });

      if (findAxFromOptions?.name) return findAxFromOptions;

      const response = await this.searchCategoryAx({ q: categoryAxName });
      const categoryAx = response.data.data?.rows ?? [];
      const findAxFromRespone = categoryAx.find((cax) => {
        const casoName = cax.name?.trim().toLowerCase();
        return casoName === caSearch;
      });
      this.categoryAxSearchOptions.push(...categoryAx);

      return findAxFromRespone;
    },

    async narratorBindings(narratorTemplate = '', parentId) {
      let tempNarrators = [];
      const tempNarratorNotFound = [];
      const Narrators = narratorTemplate.split('#') ?? [];
      for (const narrator of Narrators) {
        const narratorSearch = narrator.trim().toLowerCase();
        const findNarratorFromOptions = this.narratorSearchOptions.find(
          (aso) => {
            const asoName = aso.name?.trim().toLowerCase();
            return asoName === narratorSearch;
          }
        );

        if (findNarratorFromOptions?.name) {
          tempNarrators.push(findNarratorFromOptions);
          continue;
        }

        const query = encodeURIComponent(narratorSearch);
        const response = await this.searchNarrator(query);
        const rows = response.data.data?.rows ?? [];
        const findNarratorFromResponse = rows.find((row) => {
          const csoName = row.name?.trim().toLowerCase();
          return csoName === narratorSearch;
        });
        if (findNarratorFromResponse?.name) {
          this.narratorSearchOptions.push(findNarratorFromResponse);
          tempNarrators.push(findNarratorFromResponse);
        } else {
          tempNarratorNotFound.push(narratorSearch);
        }
      }

      this.narratorNotFound[parentId] = tempNarratorNotFound;
      return tempNarrators;
    },

    getParentInfo() {
      this.itemsAudio.forEach(async (item, index) => {
        item.isGetParent = true;
        const parentItem = await this.getParentInfoByRow(item.parent_id, index);

        item.isValidParent = parentItem?.id ? true : false;
        item.brand_id = parentItem?.brand_id;
        item.name = item?.name ? item?.name : `[AUDIO] ${parentItem.name}`;
        item.edition_code = parentItem?.edition_code
          ? parentItem?.edition_code + 'AU'
          : '';
        item.authors = (parentItem?.authors ?? []).map((val) => val.id);
        item.countries = parentItem?.countries;
        item.languages = parentItem?.languages;
        item.brand_id = parentItem?.brand_id;
        item.issue_number = (parentItem?.authors ?? [])
          .map((val) => val.name)
          .join(', ');
        item.isGetParent = false;

        this.isLoadingDone();
      });
    },

    async getParentInfoByRow(parentId) {
      const response = await this.fetchEbookById({ id: parentId });
      const parentItem = response?.rows[0];
      this.itemsParent[parentId] = parentItem;
      return parentItem;
    },

    async onChangeParentId(parentId, indexItem) {
      const parentItem = await this.getParentInfoByRow(parentId, indexItem);

      // reactive update data audio
      this.itemsAudio[indexItem].isValidParent = parentItem?.id ? true : false;
      this.itemsAudio[indexItem].name = `[AUDIO] ${parentItem.name}`;
      this.itemsAudio[indexItem].edition_code = parentItem?.edition_code
        ? parentItem?.edition_code + 'AU'
        : '';
      this.itemsAudio[indexItem].authors = (parentItem?.authors ?? []).map(
        (val) => val.id
      );
      this.itemsAudio[indexItem].issue_number = (parentItem?.authors ?? [])
        .map((val) => val.name)
        .join(', ');
      this.itemsAudio[indexItem].brand_id = parentItem?.brand_id;
      this.itemsAudio[indexItem].countries = parentItem?.countries;
      this.itemsAudio[indexItem].languages = parentItem?.languages;
      this.itemsAudio[indexItem].narrator = [];
      this.itemsAudio[indexItem].category_ax = [];
      this.itemsAudio[indexItem].release_date = new Date();
      this.itemsAudio[indexItem].release_schedule = new Date();
      this.itemsAudio[indexItem].description = '';
    },

    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchVendors({ name: query })
          .then(() => {
            this.isVendorSearch = false;
          })
          .catch(() => {
            this.isVendorSearch = false;
          });
      }, 600);
    },

    categoryAxFind(query) {
      if (!query) return;

      this.isAxSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCategoryAx(payload)
          .then((response) => {
            this.categoryAxSearchOptions = response.data.data.rows;
            this.isAxSearch = false;
          })
          .catch(() => {
            this.isAxSearch = false;
          });
      }, 600);
    },

    narratorFind(query) {
      if (!query) return;

      this.isNarratorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchNarrator(query)
          .then((response) => {
            this.narratorSearchOptions = response.data.data.rows;
            this.isNarratorSearch = false;
          })
          .catch(() => {
            this.isNarratorSearch = false;
          });
      }, 600);
    },

    async onCreateNarrator(narratorName = '') {
      const nameSplit = narratorName.split(' ');
      await this.postNarrator({
        name: narratorName,
        first_name: nameSplit[0],
        last_name: nameSplit[nameSplit.length - 1],
      });
      this.validateDataCsv();
      this.getParentInfo();
    },

    async onBulkCreateNarrator() {
      this.isLoading = true;
      const narrators = this.removeDuplicateNarratorNotFound();

      for (const narrator of narrators) {
        const nameSplit = narrator.split(' ');
        await this.postNarrator({
          name: narrator,
          first_name: nameSplit[0],
          last_name: nameSplit[nameSplit.length - 1],
        });
      }

      this.validateDataCsv();
      this.getParentInfo();
    },

    removeDuplicateNarratorNotFound() {
      let narrators = [];
      for (const key in this.narratorNotFound) {
        const narrator = this.narratorNotFound[key];
        narrators.push(...narrator);
      }
      narrators = [...new Set(narrators)];

      return narrators;
    },

    formatData({ data = {}, fields = [], defaultValue = null }) {
      const format = {};
      fields.forEach(
        (val) => (format[val.key] = data[val.key] ?? defaultValue)
      );
      format.release_date = data.release_date ?? new Date();
      format.release_schedule = data.release_date ?? new Date();
      delete format.action;
      return format;
    },

    handleAdd() {
      const newRow = this.formatData({
        fields: this.fields,
      });
      this.itemsAudio.push(newRow);
    },

    handleDelete(data = {}) {
      this.itemsAudio.splice(data.index, 1);
      delete this.narratorNotFound[data.item.parent_id];
      delete this.itemsParent[data.item.parent_id];
    },

    handleReset() {
      this.file_csv = null;
      this.itemsAudio = [];
      this.itemsParent = {};
    },

    isLoadingDone() {
      const findRowBusy = this.itemsAudio.filter((val) => {
        const isValidate = val.isValidate === true;
        const isGetParent = val.isGetParent === true;
        const isSubmit = val.isSubmit === true;
        return isValidate || isGetParent || isSubmit;
      });
      this.isLoading = !!findRowBusy.length;
    },

    permFilterVendor() {
      switch (this.auth.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          this.fetchOwnVendors();
          return false;
      }
    },

    isBulkNarratorShow() {
      const narrators = this.removeDuplicateNarratorNotFound();
      return !!narrators.length;
    },

    labelNameWithISO({ name, iso }) {
      return `${iso} - ${name}`;
    },

    rowClass(item) {
      if (!item) return;
      if (!item?.isValidParent) return 'table-danger';
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
};
</script>

<style scoped>
</style>